.table th {
  border: none !important;
  color: rgba(55, 77, 103, 0.54) !important;
}

.table td {
  font-weight: bold !important;
}

.popover-body {
  padding: 0 !important;
}

.ReactModal__Overlay--after-open {
  z-index: 40000 !important;
}

.nav-tabs .nav-link,
.nav-tabs .nav-link {
  color: #636f83 !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background: #fff;
  color: #333 !important;
  font-weight: bold !important;
}

// CLabel bold font
label {
  font-weight: 700 !important;
}
